import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VideocamIcon from '@mui/icons-material/Videocam';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReactPlayer from 'react-player'

const extensionList = (type) => {
    switch (type) {
        case "photo": return ".jpg, .jpeg, .png";
        case "file": return ".pdf, .doc, .docx, .xls, .xlsx, .csv, .txt, .jpg, .jpeg, .png, .tiff";
        default: return "";
    }
}

export class DropUploader extends Component {
    constructor(props) {
        super(props);
        this.state = { processing: false };
    }

    handleDragOverDropZone = (e) => {
        e.currentTarget.classList.add("hoverdropzone");
    }
    handleDragLeaveDropZone = (e) => {
        e.currentTarget.classList.remove("hoverdropzone");
    }
    selectedVideo = () => {
        if (this.state.selectedVideo) {
            return (
                <Box key={'uploadedVid'} className='player-wrapper'>
                    <ReactPlayer
                        url={URL.createObjectURL(this.state.selectedVideo)}
                        controls={true}
                        className='react-player'
                        width="100%"
                        height="auto"
                    />
                </Box>
            )
        }
        return null;
    }
    handleDrop = async (files, type) => {
        var ele = document.getElementById("dropZone" + type);
        if (ele) {
            ele.classList.remove("hoverdropzone");
            ele.classList.add("hoverdropzoneBusy");
        }

        this.props.toggleProcessing(true);
        if (files) {
            var numAllowed = 1;
            if (this.props.multi) {
                numAllowed = files.length;
            }
            for (var i = 0; i < numAllowed; i++) {
                var file = files[i];
                var extension = file.type;
                console.log(extension);
                if (this.props.fileType == "photo") {
                    if (extension === "image/jpeg" || extension === "image/jpg" || extension === "image/png" || extension === "image/bmp") {
                        await this.props.handleSave(file);
                    }
                }
                if (this.props.fileType == "video") {
                    if (extension.startsWith("video")) {
                        this.setState({ selectedVideo: file });
                        await this.props.handleSave(file);
                  }
                }
                if (this.props.fileType == "file") {
                    if (
                        extension === "application/pdf" ||
                        extension === "application/msword" ||
                        extension === "text/csv" ||
                        extension === "text/plain" ||
                        extension === "application/vnd.ms-excel" ||
                        extension === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                        extension === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                        extension === "image/jpeg" ||
                        extension === "image/jpg" ||
                        extension === "image/png" ||
                        extension === "image/tiff" ||
                        extension === "image/bmp"
                    ) {
                        await this.props.handleSave(file);
                    }
                }
            }
        }

        //clean up iu from any progress indicators, etc.
        if (ele) {
            ele.classList.remove("hoverdropzone");
            ele.classList.remove("hoverdropzoneBusy");
        }
        this.props.toggleProcessing(false);
    }


    dropZoneInstructions = () => {
        return (
            <>
                {(this.props.fileType === "photo" && !this.props.photoB64) &&
                    <Box sx={{ display: "inline-flex", m: "auto" }}>
                        <Box sx={{ display: { xs: "none", sm: "block" }, m: "auto" }}>
                            <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto", fontWeight: 540 }}>
                                Drag and Drop Photos
                            </Typography>
                            <InsertPhotoIcon style={{ fontSize: 75 }} sx={{ fontSize: "5rem", color: (theme) => theme.palette.secondary.main }} />
                        </Box>
                        <Box sx={{ display: { xs: "block", sm: "none" }, m: "auto" }}>
                            <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto", fontWeight: 540 }}>
                                Tap or click to add photos
                            </Typography>
                            <InsertPhotoIcon style={{ fontSize: 75 }} sx={{ fontSize: "5rem", color: (theme) => theme.palette.secondary.main }} />
                        </Box>
                    </Box>
                }
                {this.props.fileType === "file" &&
                    <Box sx={{ display: "inline-flex", m: "auto" }}>
                        <Box sx={{ display: { xs: "none", sm: "block" }, m: "auto" }}>
                            <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto", fontWeight: 540 }}>
                                Tap or click to upload a document
                            </Typography>
                            <UploadFileIcon style={{ fontSize: 75 }} sx={{ color: (theme) => theme.palette.secondary.main }} />
                        </Box>
                        <Box sx={{ display: { xs: "block", sm: "none" }, m: "auto" }}>
                            <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto", fontWeight: 540 }}>
                                Tap or click to upload a document
                            </Typography>
                            <UploadFileIcon style={{ fontSize: 75 }} sx={{ color: (theme) => theme.palette.secondary.main }} />
                        </Box>
                    </Box>
                }
                {(this.props.fileType === "video" && this.state.selectedVideo) && this.selectedVideo()}
                {(this.props.fileType === "video" && !this.state.selectedVideo) &&
                    <Box sx={{ display: "inline-flex", m: "auto" }}>
                        <Box sx={{ display: { xs: "none", sm: "block" }, m: "auto" }}>
                            <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto", fontWeight: 540 }}>
                                Drag and Drop Videos
                            </Typography>
                            <VideocamIcon style={{ fontSize: 75 }} sx={{ fontSize: "5rem", color: (theme) => theme.palette.secondary.main }} />
                        </Box>
                        <Box sx={{ display: { xs: "block", sm: "none" }, m: "auto" }}>
                            <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto", fontWeight: 540 }}>
                                Tap or click to add vides
                            </Typography>
                            <VideocamIcon style={{ fontSize: 75 }} sx={{ fontSize: "5rem", color: (theme) => theme.palette.secondary.main }} />
                        </Box>
                    </Box>
                }
            </>
            )
    }
    uploadedPhotoPreview = () => {
        return (
            <Box sx={{ maxWidth: { xs: 360, sm: 400, md: 500, }, m: "auto", backgroundColor: this.props.dark ? "#111" : "#fff", boxShadow: this.props.dark ? 4 : 8, }}>
                <img
                    src={"data:image/png;base64," + this.props.photoB64}
                    className="pointer objectfit editorimage"
                    onClick={this.handleShowFilePicker}
                    style={{  height: "100%", width: "100%", objectFit: "contain", cursor: "pointer", }}
                />
            </Box>
            )
    }
    content = () => {
        return (
            <Dropzone
                onDrop={acceptedFiles => this.handleDrop(acceptedFiles, this.props.type)}
                onDragOver={this.handleDragOverDropZone}
                onDragLeave={this.handleDragLeaveDropZone}
                noDragEventsBubbling
            >
                {({ getRootProps, getInputProps }) => (
                    <Box {...getRootProps()}
                        id={"dropZone" + this.props.type}
                        sx={{
                            cursor:"pointer",
                            display: "flex",
                            minHeight: ((this.props.fileType === "photo" && this.props.photoB64) || (this.props.fileType === "video" && this.state.selectedVideo)) ? "unset" : 300,
                            background: (theme) => this.props.dark ? theme.palette.backgroundInput.dark : theme.palette.backgroundInput.light,
                            color: this.props.dark ? "#999" : "#444",
                            textAlign: "center",
                            borderRadius: { xs:0, sm:1},
                            cursor: "pointer",
                            maxWidth: 400,
                            mx: "auto",
                            mt: this.props.dark ? 0 : 1,
                            width: ((this.props.fileType === "photo" && this.props.photoB64) || (this.props.fileType === "video" && this.state.selectedVideo)) ? "unset" : "100%",
                            //boxShadow: this.props.dark ? 0 : (((this.props.fileType === "photo" && this.props.photoB64) || (this.props.fileType === "video" && this.state.selectedVideo)) ? 0 : 8)
                        }}>
                        <input {...getInputProps()} accept={extensionList(this.props.fileType)} />
                        {(this.props.fileType === "photo" && this.props.photoB64) && this.uploadedPhotoPreview()}
                        {(this.props.fileType === "video" && this.state.selectedVideo) && this.selectedVideo()}
                        {this.dropZoneInstructions()}
                    </Box>
                )}
            </Dropzone>
        )
    }

    render() {
        return this.content();
    }
}
